import { createTheme } from "@mui/material";
import { Theme } from "@mui/material/styles";
import { BACKGROUND_COLOR, PRIMARY_COLOR, PRIMARY_TEXT_COLOR} from "./colorway";
import lightningBackground from "../assets/lightningBackground.gif";

const fontFamily = 'oxanium'

const PromptTheme: Theme = createTheme({
  palette: {
    primary: {
      main: PRIMARY_TEXT_COLOR
    },
    secondary: { main: PRIMARY_COLOR },
    background: {
      default: BACKGROUND_COLOR
    }
  },
  typography: {
    fontFamily: fontFamily,
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          color: PRIMARY_TEXT_COLOR,
          "&:hover": {
            backgroundImage: `url(${lightningBackground})`,
            opacity: 0.5,
          },
          ":disabled": {
            color: PRIMARY_TEXT_COLOR,
          }
        }
      }
    },
    MuiInput: {
      styleOverrides: {
        root: {
          color: PRIMARY_TEXT_COLOR
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: {
          borderWidth: '1px',
          borderColor: PRIMARY_COLOR,
        },
        root: {
          color: PRIMARY_TEXT_COLOR,
          "& $notchedOutline": {
            borderColor: PRIMARY_COLOR
          },
          "&:hover:not($disabled):not($focused):not($error) $notchedOutline": {
            borderColor: PRIMARY_COLOR,
            "@media (hover: none)": {
              borderColor: PRIMARY_COLOR
            }
          },
          "&$focused $notchedOutline": {
            borderColor: PRIMARY_COLOR,
            borderWidth: 1
          },
          "&.Mui-focused fieldset": {
            backgroundImage: `url(${lightningBackground})`,
            opacity: 0.15,
            borderColor: PRIMARY_COLOR,
            borderWidth: 1
          }
        },
        error: {
          "&.MuiFormHelperText-root.Mui-error" :{
            borderColor: PRIMARY_COLOR
          },
        }
      }
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          color: PRIMARY_COLOR,
        }
      }
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          color: PRIMARY_TEXT_COLOR
        }
      }
    }
  }
});

export default PromptTheme;
