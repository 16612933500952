import { Box, SxProps } from "@mui/material";
import { useAppStore } from "../../store/appInfo";
import ContestsDetailsContainer from "../containers/ContestsDetailsContainer";
import ResponsiveAppBar from "../header/ResponsiveAppBar";
import ContestsContainer from "../containers/ContestsContainer";
import { BACKGROUND_COLOR } from "../../utilities/colorway";

const ContestsPage = () => {
  const contests = useAppStore((state) => state.contests);
  return (
    <Box sx={LandingContainerStyles}>
      <ResponsiveAppBar title="Promptests" />
      <ContestsDetailsContainer contests={contests} />
      <Box sx={BodyWrapperStyles}>
        <ContestsContainer contests={contests} />
      </Box>
    </Box>
  );
};

const LandingContainerStyles: SxProps = {
  width: "100%",
  minHeight: "80rem",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  background: BACKGROUND_COLOR,
};

const BodyWrapperStyles: SxProps = {
  width: "100%",
  mb: 5,
};
export default ContestsPage;
