import { Box, SxProps } from "@mui/material";
import logo from "../../assets/logo.png";
import descriptionLogo from "../../assets/descriptionLogo.png";
import hostIcon from "../../assets/hostLogo.png";

export const Logo = () => {
  const imageURL = `${logo}`;
  return (
    <Box sx={LogoIconStyles}>
      <img alt="bottle with a message" src={imageURL} width={60} height={60} />
    </Box>
  );
};

const LogoIconStyles: SxProps = {
  width: "64",
  height: "64",
  display: { xs: "none", md: "flex" },
  mr: 2,
  mb: 1,
};

export const LogoEmbeddedInput = () => {
  const imageURL = `${logo}`;
  return (
    <Box sx={LogoEmbeddedInputStyles}>
      <img alt="bottle with a message" src={imageURL} width={30} height={30} />
    </Box>
  );
};

export const DescriptionIconEmbeddedInput = () => {
  const imageURL = `${descriptionLogo}`;
  return (
    <Box sx={LogoEmbeddedInputStyles}>
      <img alt="bottle with a message" src={imageURL} width={30} height={30} />
    </Box>
  );
};

export const HostIconEmbeddedInput = () => {
  const imageURL = `${hostIcon}`;
  return (
    <Box sx={LogoEmbeddedInputStyles}>
      <img alt="bottle with a message" src={imageURL} width={30} height={30} />
    </Box>
  );
};

const LogoEmbeddedInputStyles: SxProps = {
  display: { xs: "none", md: "flex" },
  m: 1,
};

export const Token = () => {
  const imageURL = `${logo}`;
  return (
    <Box sx={TokenStyles}>
      <img alt="bottle with a message" src={imageURL} width={30} height={30} />
    </Box>
  );
};

const TokenStyles: SxProps = {
  display: { xs: "none", md: "flex" },
  ml: -0.5,
  mr: 1,
};
