import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import logo from "../../assets/logo.png";
import { Box, Divider, SxProps } from "@mui/material";
import { ContestProps } from "../../api/contest";
import { useAppStore } from "../../store/appInfo";
import { formatBigNumber } from "../utils/helperFunctions/display";
import { useGetSubmissionsInfo } from "../../api/submissions";
import {
  PRIMARY_COLOR,
  SECONDARY_TEXT_COLOR,
  TERTIARY_COLOR,
  THIRD_TEXT_COLOR,
} from "../../utilities/colorway";
import MoneyWrapper from "../utils/MoneyWrapper";

type props = {
  contest: ContestProps;
};

const ContestView = ({ contest }: props) => {
  const chainID = useAppStore((state) => state.chainId);
  const formattedSubmissionAmount = formatBigNumber(
    chainID,
    contest.submissionFee,
    true
  );
  const formattedPoolAmount = formatBigNumber(
    chainID,
    contest.poolAmount,
    true
  );

  let imageURL = logo;
  const submission = useGetSubmissionsInfo(
    contest.address,
    chainID,
    1,
    parseInt(contest.numberSubmissions)
  );

  if (submission.length) {
    if (contest.leaderBoard.length > 0) {
      const token = parseInt(contest.leaderBoard[0]);
      imageURL = submission[token - 1].tokenURI;
    } else {
      imageURL = submission[0].tokenURI;
    }
  }

  return (
    <Card sx={CardStyles}>
      <CardMedia
        component="img"
        height="220"
        image={imageURL}
        alt="green iguana"
      />
      <CardContent sx={CardDataStyles}>
        <Box>
          <Typography sx={PromptStyles}>{contest.description}</Typography>
          <Typography sx={ContestNameStyles}>{contest.symbol}</Typography>
        </Box>
        <Box sx={InfoWrapperStyles}>
          <Divider sx={DividerStyles} />
          <Box sx={CostWrapperStyles}>
            <MoneyWrapper label="pool" value={formattedPoolAmount} />
            <MoneyWrapper label="fee" value={formattedSubmissionAmount} />
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};

const CardDataStyles: SxProps = {
  display: "flex",
  flexDirection: "column",
  height: "13rem",
};

const InfoWrapperStyles: SxProps = {
  display: "flex",
  flexDirection: "column",
  marginTop: "auto",
};

const CostWrapperStyles: SxProps = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-around",
  marginTop: "auto",
};

const DividerStyles: SxProps = {
  background: PRIMARY_COLOR,
  borderBottomWidth: "6px",
  ml: -4,
  mr: -4,
  mb: 1,
};

const PromptStyles: SxProps = {
  textAlign: "left",
  fontSize: "1.2rem",
};

const ContestNameStyles: SxProps = {
  mt: 0.5,
  textAlign: "left",
  fontSize: "1rem",
  color: THIRD_TEXT_COLOR,
};

const CardStyles: SxProps = {
  width: "100%",
  background: TERTIARY_COLOR,
  border: "10px solid " + PRIMARY_COLOR,
  borderRadius: 5,
  color: SECONDARY_TEXT_COLOR,
};

export default ContestView;
