import create from "zustand";
import { devtools, persist } from "zustand/middleware";
import { ContestProps } from "../api/contest";

interface AppState {
  chainId: number;
  rpcURL: any;
  account: string;
  recentTransaction: boolean;
  contests: ContestProps[];
  judges: string[];
  updateChainId: (chain: number) => void;
  updateRpcURL: (url: any) => void;
  updateAccount: (account: string) => void;
  updateRecentTransaction: (justFinished: boolean) => void;
  updateContests: (newcontests: ContestProps[]) => void;
  updateJudges: (newjudges: string[]) => void;
}

export const useAppStore = create<AppState>()(
  devtools(
    persist(
      (set) => ({
        chainId: 0,
        rpcURL: "",
        account: "",
        recentTransaction: false,
        contests: [],
        judges: [],
        updateRpcURL: (url) => set(() => ({ rpcURL: url })),
        updateChainId: (chain) => set(() => ({ chainId: chain })),
        updateAccount: (account) => set(() => ({ account: account })),
        updateRecentTransaction: (tx) => set(() => ({ recentTransaction: tx })),
        updateContests: (newcontests) => set(() => ({ contests: newcontests })),
        updateJudges: (newjudges) => set(() => ({ judges: newjudges })),
      }),
      {
        name: "app-storage",
      }
    )
  )
);
