import {
  Box,
  Button,
  Container,
  Grid,
  SxProps,
  Typography,
} from "@mui/material";
import { useMemo, useState } from "react";
import { ContestProps } from "../../api/contest";
import { useGetSubmissionsInfo } from "../../api/submissions";
import { useAppStore } from "../../store/appInfo";
import SubmissionView from "../views/SubmissionView";
import ArrowForwardOutlinedIcon from "@mui/icons-material/ArrowForwardOutlined";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import { TRANSPARENT, PRIMARY_TEXT_COLOR } from "../../utilities/colorway";
import ContestSummaryContainer from "./ContestSummaryContainer";

type props = {
  contest: ContestProps;
};

const SUBMISSIONS_PER_PAGE = 9;

const SubmissionsContainer = ({ contest }: props) => {
  const chainId = useAppStore((state) => state.chainId);
  const [currentPage, setPage] = useState<number>(0);

  // Requesting all submissions for a contest
  const submissions = useGetSubmissionsInfo(
    contest.address,
    chainId,
    1,
    parseInt(contest.numberSubmissions)
  );

  const shownSubmissions = useMemo(() => {
    return submissions.slice(
      currentPage * SUBMISSIONS_PER_PAGE,
      (currentPage + 1) * SUBMISSIONS_PER_PAGE
    );
  }, [submissions, currentPage]);

  const showNextPageButton: boolean = useMemo(
    () =>
      currentPage + 1 < Math.ceil(submissions.length / SUBMISSIONS_PER_PAGE),
    [currentPage, submissions.length]
  );
  const showPrevPageButton: boolean = useMemo(
    () => currentPage !== 0,
    [currentPage]
  );

  const votingPeriodOver = new Date(contest.duration) < new Date();

  return (
    <Container sx={ContestContainerStyles} maxWidth={false}>
      <ContestSummaryContainer contest={contest} />
      <Grid sx={GridStyles} container spacing={3}>
        {shownSubmissions.map((submission, index) => (
          <Grid sx={ContestCardStyles} item sm={4} key={contest.address}>
            <SubmissionView
              submission={submission}
              leadingSubmission={contest.leaderBoard.includes(
                submission.tokenID.toString()
              )}
              sponsor={contest.sponsor}
              votingPeriodOver={votingPeriodOver}
            />
          </Grid>
        ))}
      </Grid>

      {shownSubmissions.length && (
        <Box sx={CenteredBoxStyle} mt={3}>
          <Button
            variant="outlined"
            onClick={() => showPrevPageButton && setPage(currentPage - 1)}
            startIcon={<ArrowBackOutlinedIcon />}
          >
            Back
          </Button>
          <Typography sx={PaginationTextStyles} variant="h4">
            {currentPage + 1}
          </Typography>
          <Button
            variant="outlined"
            onClick={() => showNextPageButton && setPage(currentPage + 1)}
            endIcon={<ArrowForwardOutlinedIcon />}
          >
            Next
          </Button>
        </Box>
      )}
    </Container>
  );
};

const CenteredBoxStyle: SxProps = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  alignSelf: "center",
};

const GridStyles: SxProps = {
  padding: "0rem 2rem 0rem",
};

const PaginationTextStyles: SxProps = {
  textAlign: "center",
  color: PRIMARY_TEXT_COLOR,
  m: 2,
};

const ContestContainerStyles: SxProps = {
  justifyItems: "center",
  alignItems: "center",
  background: TRANSPARENT,
  borderRadius: 15,
  width: "85%",
  padding: "3.5rem 5rem 0rem",
  height: "min-content",
  mb: 3,
};

const ContestCardStyles: SxProps = {
  display: "flex",
  justifyContent: "space-around",
  alignItems: "center",
};

export default SubmissionsContainer;
