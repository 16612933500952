import { Button, SxProps } from "@mui/material";
import { TransactionStatus } from "@usedapp/core";
import { useEffect, useState } from "react";

type props = {
  defaultText: string;
  pendingText: string;
  unsuccessfulText: string;
  successfulText: string;
  defaultStyle: SxProps;
  pendingStyle: SxProps;
  unsuccessfulStyle: SxProps;
  successfulStyle: SxProps;
  state: TransactionStatus;
  onClick: any;
};

const SWITCH_TO_DEFAULT = 5000;

const TransactionStatusButton = ({
  defaultText,
  pendingText,
  unsuccessfulText,
  successfulText,
  defaultStyle,
  pendingStyle,
  unsuccessfulStyle,
  successfulStyle,
  state,
  onClick,
}: props) => {
  const [disableButton, setDisableButton] = useState(false);
  const [buttonText, setButtonText] = useState(defaultText);
  const [buttonStyle, setButtonStyle] = useState<SxProps>(defaultStyle);

  useEffect(() => {
    switch (state.status) {
      case "Success":
        setDisableButton(false);
        setButtonText(successfulText);
        setButtonStyle(successfulStyle);
        setTimeout(() => {
          setButtonText(defaultText);
          setButtonStyle(defaultStyle);
        }, SWITCH_TO_DEFAULT);
        break;
      case "PendingSignature":
        setDisableButton(true);
        setButtonText("signature...");
        setButtonStyle(pendingStyle);
        break;
      case "Mining":
        setDisableButton(true);
        setButtonText(pendingText);
        setButtonStyle(pendingStyle);
        break;
      case "Fail":
        setDisableButton(false);
        setButtonText(unsuccessfulText);
        setButtonStyle(unsuccessfulStyle);
        setTimeout(() => {
          setButtonText(defaultText);
          setButtonStyle(defaultStyle);
        }, SWITCH_TO_DEFAULT);
        break;
      case "Exception":
        setDisableButton(false);
        setButtonText(unsuccessfulText);
        setButtonStyle(unsuccessfulStyle);
        setTimeout(() => {
          setButtonText(defaultText);
          setButtonStyle(defaultStyle);
        }, SWITCH_TO_DEFAULT);
        break;
    }
  }, [state.status]);

  return (
    <Button sx={buttonStyle} disabled={disableButton} onClick={onClick}>
      {buttonText}
    </Button>
  );
};

export default TransactionStatusButton;
