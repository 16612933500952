import { useEthers } from "@usedapp/core";
import { useAppStore } from "../../store/appInfo";
import { Chip, SxProps } from "@mui/material";
import "@fontsource/oxanium/800.css";
import Button from "@mui/material/Button";
import { useEffect } from "react";
import { BACKGROUND_COLOR, QUATERNARY_COLOR, SENARY_COLOR, TERTIARY_COLOR } from "../../utilities/colorway";

const Wallet = () => {
  const { active, account } = useEthers();
  const updateAccount = useAppStore((state) => state.updateAccount);

  useEffect(() => {
    if (account) {
      updateAccount(account);
    }
  }, [active, account]);

  return (
    <div>
      <div>
        {!active || !account ? (
          <ConnectWallet />
        ) : (
          <Chip
            sx={WalletConnectionStyle}
            label={
              account.slice(0, 7) +
              "..." +
              account.slice(account.length - 5, account.length)
            }
          />
        )}
      </div>
    </div>
  );
};

const ConnectWallet = () => {
  const { activateBrowserWallet } = useEthers();

  return (
    <Button
      style={{
        backgroundColor: SENARY_COLOR,
        border: "1.5px solid " + BACKGROUND_COLOR,
        width: "fit-content",
        padding: "2px 35px 2px",
        color: "#FFFFFF",
        fontSize: "1rem",
        letterSpacing: ".02rem",
        justifyContent: "center",
        textAlign: "center",
        borderRadius: 10,
      }}
      onClick={() => {
        activateBrowserWallet();
      }}
    >
      Connect
    </Button>
  );
};

const WalletConnectionStyle: SxProps = {
  backgroundColor: SENARY_COLOR,
  border: "4px solid " + BACKGROUND_COLOR,
  width: "fit-content",
  padding: "1rem 1rem 1rem",
  color: "#FFFFFF",
  fontSize: "1.1rem",
  letterSpacing: ".02rem",
  justifyContent: "center",
  textAlign: "center",
};

export default Wallet;
