import { Box, SxProps } from "@mui/material";
import { ExtendedBoxProps } from "../../types/Types";
// import { ContainerProps } from "../../types/Types";

const appContainerStyles: SxProps = {
  background: 'black',
  // width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
}


const AppContainer = (props?: ExtendedBoxProps) => {
  return (
    <Box sx={appContainerStyles}>
      {props?.children}
    </Box>
  )
}

export default AppContainer;
