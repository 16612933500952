import { BigNumber } from "ethers";
import { commify } from "ethers/lib/utils";
import { ContestProps } from "../../../api/contest";

export const sumPoolAmounts = (contests: ContestProps[]) => {
  if (!contests) {
    return "0"
  }
  const initialValue = BigNumber.from("0");
  const sumWithInitial = contests.reduce((previousValue, currentValue) => {
    const curr = BigNumber.from(currentValue.poolAmount);
    return previousValue.add(curr);
  }, initialValue);
  return sumWithInitial.toString();
};

export const sumContestSubmissions = (contests: ContestProps[]) => {
  if (!contests) {
    return "0"
  }
  const initialValue: number = 0;
  const sumWithInitial = contests.reduce((previousValue, currentValue) => {
    const curr = parseInt(currentValue.numberSubmissions);
    return previousValue + curr;
  }, initialValue);
  return commify(sumWithInitial);
};
