import { useContractFunction } from "@usedapp/core";
import { Contract, utils } from "ethers"
import { contestFactoryAddresses } from "../config/constants";
import { ContestABI, ContestFactoryABI } from "./abi"

export const useVoteForContest = (address: string) => {
    const abi = new utils.Interface(ContestABI);

    const contest = new Contract(address, abi);

    const {state, send} = useContractFunction(contest, "vote");
    return {state, send};
}

export const useSetJudge = (chainId: number) => {
    const contractFactory = contestFactoryAddresses[chainId];
    const abi = new utils.Interface(ContestFactoryABI);

    const contest = new Contract(contractFactory, abi);

    const {state, send} = useContractFunction(contest, "registerJudge");
    return {state, send};
}