import { SxProps } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import ContestView from "../views/ContestView";
import { useNavigate } from "react-router-dom";
import { ContestProps } from "../../api/contest";
import { Container } from "@mui/material";

type props = {
  contests: ContestProps[];
};

const ContestsContainer = ({ contests }: props) => {
  const navigate = useNavigate();
  return (
    <Container sx={ContestContainerStyles} maxWidth={false}>
      <Grid sx={GridStyles} container spacing={3}>
        {contests.map((contest) => (
          <Grid
            sx={ContestCardStyles}
            item
            sm={4}
            key={contest.address}
            onClick={() => {
              navigate("/promptest/" + contest.address);
            }}
          >
            <ContestView contest={contest} />
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

const GridStyles: SxProps = {
  padding: "0rem 2rem 0rem",
};

const ContestContainerStyles: SxProps = {
  justifyItems: "center",
  alignItems: "center",
  borderRadius: 15,
  width: "85%",
  padding: "1rem 5rem 2rem",
  height: "min-content",
};

const ContestCardStyles: SxProps = {
  display: "flex",
  justifyContent: "space-around",
  alignItems: "center",
};

export default ContestsContainer;
