import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import { Grid, SxProps } from "@mui/material";
import Wallet from "../utils/Wallet";
import { useEthers } from "@usedapp/core";
import { useAppStore } from "../../store/appInfo";
import { useEffect } from "react";
import { config } from "../../config/provider";
import { Logo } from "../utils/Icons";
import { PRIMARY_COLOR, SECONDARY_COLOR } from "../../utilities/colorway";

type props = {
  title: string;
};

const ResponsiveAppBar = ({ title }: props) => {
  const { chainId, account } = useEthers();

  const updateChainId = useAppStore((state) => state.updateChainId);
  const updateRpcURL = useAppStore((state) => state.updateRpcURL);
  const updateAccount = useAppStore((state) => state.updateAccount);

  
  // Updates the RPC, chainID, and account info as things are networks are changed
  // or accounts switched
  useEffect(() => {
    if (chainId) {
      updateChainId(chainId);
      if (config.readOnlyUrls) {
        const url = config.readOnlyUrls[chainId];
        if (url) {
          updateRpcURL(url);
        }
      }
    } 

    if (account) {
      updateAccount(account);
    }
  }, [
    chainId,
    account,
    updateChainId,
    updateRpcURL,
    updateAccount,
  ]);

  // Navigation details
  const pages = ["home", "promptests", "create"];
  const navigate = useNavigate();

  const navigateTo = (destination: string) => {
    if (destination === "home") {
      navigate("/");
    } else {
      navigate("/" + destination);
    }
  };

  return (
    <AppBar position="sticky" sx={AppBarStyles}>
      <Grid container direction={"row"} sx={GridStyles}>
        <Grid item xs={4} sx={LogoStyles}>
          <Logo />
          <Typography
            variant="h3"
            noWrap
            component="a"
            href="/"
            sx={LogoNameStyles}
          >
            Prompt
          </Typography>
        </Grid>
        <Grid item xs={3} sx={TitleStyles}>
          <Typography
            variant="h4"
            noWrap
            component="a"
            href="/"
            sx={TitleStyles}
          >
            {title}
          </Typography>
        </Grid>
        <Grid item xs={4} sx={NavigationWrapperStyles}>
          <Box sx={NavigationOptions}>
            {pages.map((page) => (
              <Button
                key={page}
                onClick={() => navigateTo(page)}
                sx={NavigationButtonStyles}
              >
                {page}
              </Button>
            ))}
          </Box>
          <Wallet />
        </Grid>
      </Grid>
    </AppBar>
  );
};

const GridStyles: SxProps = {
  justifyContent: "space-between",
};

const LogoStyles: SxProps = {
  flexGrow: 1,
  display: { xs: "none", md: "flex" },
  justifyContent: "left",
  alignItems: "center",
  color: "white",
};

const TitleStyles: SxProps = {
  display: { xs: "none", md: "flex" },
  letterSpacing: ".025rem",
  color: "white",
  textDecoration: "none",
  alignItems: "center",
  justifyContent: "center",
};

const NavigationWrapperStyles: SxProps = {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-around",
};

const NavigationOptions: SxProps = {
  display: { xs: "none", md: "flex" },
  flexDirection: "row",
  justifyContent: "right",
  mr: 2,
  width: "min-content",
};

const NavigationButtonStyles: SxProps = {
  my: 2,
  display: "block",
  fontFamily: "oxanium",
  fontSize: "1.2rem",
};

const AppBarStyles: SxProps = {
  backgroundColor: PRIMARY_COLOR,
  padding: ".2rem 1.5rem .2rem",
  justifyContent: "center",
  borderBottom: "3px solid " + SECONDARY_COLOR,
};

const LogoNameStyles: SxProps = {
  display: { xs: "none", md: "flex" },
  fontFamily: "oxanium",
  letterSpacing: ".025rem",
  color: "inherit",
  textDecoration: "none",
};

export default ResponsiveAppBar;
