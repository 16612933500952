import { Chip, Typography } from "@mui/material";
import { Box, SxProps } from "@mui/system";
import { MONEY_TEXT_COLOR } from "../../utilities/colorway";

type props = {
  label: string;
  value: string;
};

const MoneyWrapper = ({ label, value }: props) => {
  return (
    <Box sx={PriceStyles}>
      <Typography sx={DescriptionStyles}>{label} </Typography>
      <Chip sx={DataInfoContainer} label={value.toUpperCase()} />
    </Box>
  );
};

const DataInfoContainer: SxProps = {
  width: "min-content",
  fontFamily: "oxanium",
  color: MONEY_TEXT_COLOR,
  fontSize: "1.1rem",
  letterSpacing: ".02rem",
  justifyContent: "center",
  textAlign: "center",
  border: "2px solid " + MONEY_TEXT_COLOR,
  borderRadius: 15,
};

const PriceStyles: SxProps = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  alignItems: "center",
  gap: 0.5,
};

const DescriptionStyles: SxProps = {
  textAlign: "left",
  fontFamily: "oxanium",
  fontSize: "1rem",
};

export default MoneyWrapper;
