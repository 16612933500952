import {
  FormControl,
  SxProps,
  TextField,
  Typography,
  Grid,
} from "@mui/material";
import { useState } from "react";
import { parseUnits } from "ethers/lib/utils";
import { useCreateNewContest } from "../../api/contestFactory";
import { useAppStore } from "../../store/appInfo";
import {
  ERROR,
  MONEY_TEXT_COLOR,
  PRIMARY_COLOR,
  PRIMARY_TEXT_COLOR,
  QUATERNARY_COLOR,
  SENARY_COLOR,
  TERTIARY_COLOR,
} from "../../utilities/colorway";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, { Dayjs } from "dayjs";
import { Box } from "@mui/system";
import { LogoEmbeddedInput, DescriptionIconEmbeddedInput, HostIconEmbeddedInput } from "../utils/Icons";
import TransactionStatusButton from "../utils/TransactionStatusButton";

const NewContestForm = () => {
  const chainId = useAppStore((state) => state.chainId);

  // Inputs that are required to create a new contest
  const [name, setName] = useState("");
  const [symbol, setSymbol] = useState("");
  const [description, setDescription] = useState("");
  const [potAmount, setPotAmount] = useState("");
  const [submissionFee, setSubmissionFee] = useState("");
  const currentTime = new Date();
  const [value, setValue] = useState<Dayjs | null>(dayjs(currentTime));

  const { state: newContestState, send: createContest } =
    useCreateNewContest(chainId);

  const handleChange = (newValue: Dayjs | null) => {
    setValue(newValue);
  };

  const createNewContest = () => {
    const now = new Date();
    const durationInSeconds = value?.diff(now, "second");
    const potAmountInCorrectBase = parseUnits(potAmount, 18);
    const submissionFeeInCorrectBase = parseUnits(submissionFee, 18);

    createContest(
      name,
      symbol,
      description,
      durationInSeconds,
      submissionFeeInCorrectBase,
      { value: potAmountInCorrectBase }
    );
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <FormControl sx={FormControlStyles}>
        <Box>
          <Box sx={FormContainerStyles}>
            <Box sx={ContestDetailsStyles}>
              <Box>
                <Typography sx={DescriptionHeaderStyles}>
                  Description that will guide users when entering prompts
                </Typography>
                <TextField
                  sx={InfoStyles}
                  variant="standard"
                  onChange={(e) => setDescription(e.target.value)}
                  InputProps={{
                    startAdornment: <DescriptionIconEmbeddedInput />,
                    disableUnderline: true,
                    style: InputFontStyles,
                  }}
                />
              </Box>
              <Grid container sx={NameSymbolContainerStyles} width={"100%"}>
                <Grid item xs={5.7}>
                  <Typography sx={DescriptionHeaderStyles}>
                    Promptest Host
                  </Typography>
                  <TextField
                    sx={InfoStyles}
                    variant="standard"
                    onChange={(e) => setName(e.target.value)}
                    InputProps={{
                      startAdornment: <HostIconEmbeddedInput />,

                      disableUnderline: true,
                      style: InputFontStyles,
                    }}
                  />
                </Grid>
                <Grid item xs={5.7}>
                  <Typography sx={DescriptionHeaderStyles}>
                    Symbol, Twitter handle, or Acronym
                  </Typography>
                  <TextField
                    sx={InfoStyles}
                    variant="standard"
                    onChange={(e) => setSymbol(e.target.value)}
                    InputProps={{
                      startAdornment: <LogoEmbeddedInput />,

                      disableUnderline: true,
                      style: InputFontStyles,
                    }}
                  />
                </Grid>
              </Grid>
            </Box>

            <Grid
              container
              sx={CenteredBoxStyle}
              justifyContent="center"
              alignItems="center"
              gap={1}
            >
              <Grid item xs={4} sx={{ width: "100%" }}>
                <Typography sx={PaymentStyles}>End Date</Typography>
                <Box sx={DateStyle}>
                  <DateTimePicker
                    value={value}
                    onChange={handleChange}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </Box>
              </Grid>
              <Grid item xs={3}>
                <Typography sx={PaymentStyles}>Pot Amount</Typography>
                <TextField
                  sx={DateStyle}
                  onChange={(e) => setPotAmount(e.target.value)}
                  InputProps={{
                    disableUnderline: true,
                    style: InputFontStyles,
                  }}
                />
              </Grid>
              <Grid item xs={3}>
                <Typography sx={PaymentStyles}>Submission Fee</Typography>
                <TextField
                  sx={DateStyle}
                  onChange={(e) => setSubmissionFee(e.target.value)}
                  InputProps={{
                    disableUnderline: true,
                    style: InputFontStyles,
                  }}
                />
              </Grid>
            </Grid>
          </Box>
        </Box>
        <TransactionStatusButton
          defaultText={"create a promptest"}
          pendingText={"creating promptest..."}
          unsuccessfulText={"unsuccessful creation"}
          successfulText={"promptest created!"}
          defaultStyle={ButtonStyles}
          pendingStyle={ButtonStyles}
          successfulStyle={ButtonSuccessStyles}
          unsuccessfulStyle={ButtonFailStyles}
          state={newContestState}
          onClick={() => createNewContest()}
        />
      </FormControl>
    </LocalizationProvider>
  );
};

const ButtonStyles: SxProps = {
  background: PRIMARY_COLOR,
  alignSelf: "center",
  fontSize: "1.2rem",
  width: "70%",
  pl: 4,
  pr: 4,
  ml: 3,
};

const ButtonSuccessStyles: SxProps = {
  background: MONEY_TEXT_COLOR,
  alignSelf: "center",
  fontSize: "1.2rem",
  width: "70%",
  pl: 4,
  pr: 4,
  ml: 3,
};

const ButtonFailStyles: SxProps = {
  background: ERROR,
  alignSelf: "center",
  fontSize: "1.2rem",
  width: "70%",
  pl: 4,
  pr: 4,
  ml: 3,
};

const InputFontStyles = {
  fontSize: "1.1rem",
};

const FormContainerStyles: SxProps = {
  background: TERTIARY_COLOR,
  padding: 6,
  borderRadius: 10,
};

const NameSymbolContainerStyles: SxProps = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
};

const ContestDetailsStyles: SxProps = {
  display: "flex",
  flexDirection: "column",
  gap: 3,
  color: PRIMARY_TEXT_COLOR,
};

const InfoStyles: SxProps = {
  display: "flex",
  background: QUATERNARY_COLOR,
  borderRadius: 4,
  color: PRIMARY_TEXT_COLOR,
  minHeight: "3rem",
};

const DescriptionHeaderStyles: SxProps = {
  textAlign: "left",
  color: PRIMARY_TEXT_COLOR,
  fontSize: "1.2rem",
  mb: 0.5,
};

const PaymentStyles: SxProps = {
  textAlign: "center",
  color: PRIMARY_TEXT_COLOR,
};

const FormControlStyles: SxProps = {
  background: SENARY_COLOR,
  borderRadius: 15,
  margin: "0px 0px 50px",
  width: "50%",
  padding: "2% 2% 2%",
  gap: 3,
};

const DateStyle: SxProps = {
  background: QUATERNARY_COLOR,
  width: "fit-content",
  borderRadius: 1,
  padding: 0,
  color: "black",
};

const CenteredBoxStyle: SxProps = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  mt: 5,
  alignItems: "center",
};

export default NewContestForm;
