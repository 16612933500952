import {
  FormControl,
  SxProps,
  TextField,
  Typography,
  Box,
  Button,
} from "@mui/material";
import { useState } from "react";
import Grid from "@mui/material/Grid";
import { useMakeASubmission } from "../../api/submissions";
import { useAppStore } from "../../store/appInfo";
import logo from "../../assets/logo.png";

import {
  ERROR,
  MONEY_TEXT_COLOR,
  PRIMARY_COLOR,
  PRIMARY_TEXT_COLOR,
  QUATERNARY_COLOR,
  QUINARY_COLOR,
  SECONDARY_COLOR,
  SENARY_COLOR,
  TERTIARY_COLOR,
} from "../../utilities/colorway";
import { submitAndGeneratePrompt } from "../../api/promptGeneration";
import { formatBigNumber } from "../utils/helperFunctions/display";
import MoneyWrapper from "../utils/MoneyWrapper";
import { Token } from "../utils/Icons";
import { ContestProps } from "../../api/contest";
import TransactionStatusButton from "../utils/TransactionStatusButton";

type props = {
  contest: ContestProps;
};

const NewContestSubmissionForm = ({ contest }: props) => {
  const chainId = useAppStore((state) => state.chainId);
  const [prompt, setPrompt] = useState("");
  const [disableGenerate, setDisableGenerate] = useState(false);
  const [url, setUrl] = useState("");

  const { state: submissionState, send: mint } = useMakeASubmission(
    contest.address
  );

  const formattedSubmissionFee = formatBigNumber(
    chainId,
    contest.submissionFee,
    true
  );

  const createNewSubmission = async () => {
    if (url.length !== 0) {
      mint(url, prompt, { value: contest.submissionFee });
    }
  };

  return (
    <FormControl sx={FormControlStyles}>
      <Grid container spacing={1}>
        <Grid
          item
          xs={12}
          alignItems="center"
          display={"flex"}
          gap={2}
          mb={0.5}
        >
          <Typography sx={TypographyHeaderStyles} variant="h5">
            Submission Fee
          </Typography>
          <MoneyWrapper label="" value={formattedSubmissionFee} />
        </Grid>
        <Grid
          item
          xs={12}
          justifyContent="space-around"
          display={"flex"}
          mb={1}
        >
          <TextField
            sx={PromptStyle}
            onChange={(e) => setPrompt(e.target.value)}
            InputProps={{
              startAdornment: <Token />,
              disableUnderline: true,
              style: InputFontStyles,
            }}
          />
          <Button
            sx={ButtonStyles}
            disabled={disableGenerate}
            onClick={async () => {
              setDisableGenerate(true);
              setUrl(await submitAndGeneratePrompt(prompt));
              setDisableGenerate(false);
            }}
          >
            <Typography sx={TypographyStyles}>
              {disableGenerate ? "Loading..." : "Generate"}
            </Typography>
          </Button>
        </Grid>
        <Grid
          item
          xs={12}
          display={"flex"}
          justifyContent={"center"}
          alignSelf={"center"}
        >
          <DefaultImage image={url !== "" ? url : logo} />
        </Grid>
        <Grid
          item
          xs={12}
          mt={1}
          display={"flex"}
          justifyContent={"space-around"}
          width={"min-content"}
        >
          <TransactionStatusButton
            defaultText={"Enter Promptest"}
            pendingText={"Minting..."}
            unsuccessfulText={"Submission Unsuccessful"}
            successfulText={"Submission Successful!"}
            defaultStyle={ButtonStyles}
            pendingStyle={ButtonStyles}
            successfulStyle={ButtonSuccessStyles}
            unsuccessfulStyle={ButtonFailStyles}
            state={submissionState}
            onClick={() => createNewSubmission()}
          />
        </Grid>
      </Grid>
    </FormControl>
  );
};

const DefaultImage = ({ image }: { image: string }) => {
  return (
    <Box sx={DefaultImageStyles}>
      <img
        alt="bottle with a message in it"
        width={600}
        height={600}
        src={image}
      />
    </Box>
  );
};

const InputFontStyles = {
  fontSize: "1.3rem",
  color: "white",
};

const ButtonStyles: SxProps = {
  background: PRIMARY_COLOR,
  alignSelf: "center",
  fontSize: "1.2rem",
  pl: 4,
  pr: 4,
  ml: 3,
};

const ButtonSuccessStyles: SxProps = {
  background: MONEY_TEXT_COLOR,
  alignSelf: "center",
  fontSize: "1.2rem",
  pl: 4,
  pr: 4,
  ml: 3,
};

const ButtonFailStyles: SxProps = {
  background: ERROR,
  fontSize: "1.2rem",
  pl: 4,
  pr: 4,
  ml: 3,
};

const PromptStyle: SxProps = {
  background: QUATERNARY_COLOR,
  width: "80%",
  borderRadius: 1,
  padding: 0,
  color: "black",
};

const TypographyHeaderStyles: SxProps = {
  textAlign: "left",
  color: PRIMARY_TEXT_COLOR,
};

const DefaultImageStyles: SxProps = {
  background: "black",
  borderRadius: 3,
};

const FormControlStyles: SxProps = {
  background: SENARY_COLOR,
  width: "min-content",
  padding: 4,
  gap: 5,
  borderRadius: "25px",
  mb: "10%",
  mt: 5,
};

const TypographyStyles: SxProps = {
  fontSize: "1.2rem",
};

export default NewContestSubmissionForm;
