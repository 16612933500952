import { commify, formatUnits } from "ethers/lib/utils"
import { decimalsToFormatByChainID } from "../../../config/constants"

export const formatBigNumber = (chainID: number, amount : string, commas? : boolean) => {
    if (!decimalsToFormatByChainID[chainID]) {
        return "";
    }
    const [decimals, currency] = decimalsToFormatByChainID[chainID];
    const formattedString = formatUnits(amount, decimals);
    return (commas ? commify(formattedString) : formattedString) + " " + currency;
}