import { CallResult, useCalls, useContractFunction } from "@usedapp/core";
import { BigNumber, Contract, utils } from "ethers";
import { ContestABI } from "./abi";

export type SubmissionProps = {
  address: string;
  tokenID: string;
  tokenURI: string;
  votes: string;
  submissionAccount: string;
  prompt: string;
  owner: string;
};

// Hook that lets users create a new contest. Requires a signature
export const useMakeASubmission = (address: string) => {
  const abi = new utils.Interface(ContestABI);

  const contest = new Contract(address, abi);

  const { state, send } = useContractFunction(contest, "mint");
  return { state, send };
};

export const NUMBER_REQUESTS_FOR_A_SUBMISSION = 4;

export const useGetSubmissionsInfo = (
  address: string,
  chainId: number,
  from: number,
  to: number
) => {
  let calls = [];
  for (let i = from; i <= to; i++) {
    const txInfo = buildSubmissionInfoTransaction(address, i);
    calls.push(txInfo);
  }
  const flattenedCalls = calls.flat();
  const result = useCalls(flattenedCalls, { chainId: chainId });

  try {
    const array_chunks = (array: CallResult<Contract, string>[]) => {
      if (!array.length || !array[0]) {
        return [];
      }

      const rep = array.map((array) => array?.value);
      const chunks = [];

      let tokenID = from;
      for (
        let i = 0;
        i < array.length - 1;
        i += NUMBER_REQUESTS_FOR_A_SUBMISSION
      ) {
        const slice = rep.slice(i, i + NUMBER_REQUESTS_FOR_A_SUBMISSION);
        slice.push(address);
        slice.push(tokenID);

        chunks.push(slice);

        tokenID += 1;
      }
      return chunks;
    };

    const processedContests = array_chunks(result);
    let submissionInfos: SubmissionProps[] = [];
    processedContests.forEach((submission) => {
      const info: SubmissionProps = buildSubmissionInfo(submission);
      submissionInfos.push(info);
    });
    return submissionInfos;
  } catch {
    return [];
  }
};

export const buildSubmissionInfo = (tokenData: any): SubmissionProps => {
  const user = tokenData[0][0];
  const numberVotes: BigNumber = tokenData[0][1];
  const tokenURI = tokenData[1][0];

  const result: SubmissionProps = {
    address: tokenData[4],
    tokenID: tokenData[5],
    tokenURI: tokenURI,
    votes: numberVotes.toString(),
    submissionAccount: user,
    prompt: tokenData[2][0],
    owner: tokenData[3][0],
  };

  return result;
};

export const buildSubmissionInfoTransaction = (
  address: string,
  tokenID: number
) => {
  const abi = new utils.Interface(ContestABI);
  const contestContract = new Contract(address, abi);
  return [
    // 0
    {
      contract: contestContract,
      method: "submissions",
      args: [tokenID],
    },
    // 1
    {
      contract: contestContract,
      method: "tokenURI",
      args: [tokenID],
    },
    // 2
    {
      contract: contestContract,
      method: "prompt",
      args: [tokenID],
    },
    // 3
    {
      contract: contestContract,
      method: "ownerOf",
      args: [tokenID],
    },
  ];
};
