import "./App.css";
import { Route, Routes } from "react-router-dom";
import AppContainer from "./components/containers/AppContainer";
import ContestsPage from "./components/pages/ContestsPage";
import CreateContestPage from "./components/pages/CreateContestPage";
import ContestPage from "./components/pages/ContestPage";
import { ThemeProvider } from "@emotion/react";
import PromptTheme from "./utilities/theme";
import { useAppStore } from "./store/appInfo";
import { useContestsInfo } from "./api/contest";
import { useEffect, useState } from "react";
import { getAllContests, getAllJudges } from "./api/contestFactory";
import CommunityPage from "./components/pages/CommunityPage";

function App() {
  const chainId = useAppStore((state) => state.chainId);
  const updatedChainId = useAppStore((state) => state.chainId);
  const recentTransaction = useAppStore((state) => state.recentTransaction);
  const rpcURL = useAppStore((state) => state.rpcURL);

  const updateContests = useAppStore((state) => state.updateContests);
  const updateRecentTransaction = useAppStore(
    (state) => state.updateRecentTransaction
  );
  const updateJudges = useAppStore((state) => state.updateJudges);

  // Update the contests that exist with the most recent information
  const [contestAddresses, setContestAddresses] = useState<string[]>([]);

  const wrapper = async () => {
    setContestAddresses(await getAllContests(rpcURL, updatedChainId));
    updateJudges(await getAllJudges(rpcURL, updatedChainId));
  };

  useEffect(() => {
    try {
      wrapper();
    } catch (error) {
      setContestAddresses([]);
    }
    setTimeout(() => updateRecentTransaction(!recentTransaction), 5000);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recentTransaction, chainId]);

  const contests = useContestsInfo(contestAddresses, updatedChainId, rpcURL);
  updateContests(contests);

  return (
    <ThemeProvider theme={PromptTheme}>
      <AppContainer>
        <Routes>
          <Route path="/promptests" element={<ContestsPage />} />
          <Route path="/create" element={<CreateContestPage />} />
          <Route path="/" element={<CommunityPage />} />
          <Route path="/promptest/:address" element={<ContestPage />} />
        </Routes>
      </AppContainer>
    </ThemeProvider>
  );
}

export default App;
