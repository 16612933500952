import { Box, SxProps } from "@mui/material";
import NewContestForm from "../inputs/NewContestForm";
import ResponsiveAppBar from "../header/ResponsiveAppBar";
import CreateDetailsContainer from "../containers/CreateDetailsContainer";
import { BACKGROUND_COLOR } from "../../utilities/colorway";

const CreateContestPage = () => {
  return (
    <div
      style={{
        background: BACKGROUND_COLOR,
        width: "100%",
        height: "80rem",
      }}
    >
      <ResponsiveAppBar title="Create" />
      <Box sx={landingContainerStyles}>
        <CreateDetailsContainer />
        <NewContestForm />
      </Box>
    </div>
  );
};

const landingContainerStyles: SxProps = {
  backgroundColor: BACKGROUND_COLOR,
  width: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
};

export default CreateContestPage;
