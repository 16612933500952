export type Color = string;

// Primaries
export const PRIMARY_COLOR: Color = "#2c0ff9";
export const SECONDARY_COLOR: Color = "#3f36f2";
export const TERTIARY_COLOR: Color = "#09042c";
export const QUATERNARY_COLOR: Color = "#0051fe";
export const QUINARY_COLOR: Color = "#3e3eec";
export const SENARY_COLOR: Color = "#19195f";

export const BACKGROUND_COLOR: Color = "#110f26";

// Primary text colors
export const PRIMARY_TEXT_COLOR: Color = "#FFFFFF";
export const SECONDARY_TEXT_COLOR: Color = "#c0d8fd";
export const THIRD_TEXT_COLOR: Color = "#d3e3fb";
export const MONEY_TEXT_COLOR: Color = "#43d343";

export const TRANSPARENT: Color = "transparent";

// System Colors
export const SUCCESS: Color = "";
export const ERROR: Color = "#c24040";

