import { promptGenerationEndPoint } from "../config/constants";

export const submitAndGeneratePrompt = async (prompt: string) => {
  const data = {
    version: "cc201941f43a4b6299d31f28a9745f2c33523b9d78a92cf7261fcab3fc36fd37",
    input: {
      prompt: prompt,
      width : 768,
      height : 768,
      prompt_strength : 0.9,
      num_outputs : 1,
      num_inference_steps : 100
    }
  };

  // REPLICATE_API_TOKEN=a0ef3c831ebeabcae041c46951850e27e5e37172
  // curl -s -X POST \
  // -d '{"version": "cc201941f43a4b6299d31f28a9745f2c33523b9d78a92cf7261fcab3fc36fd37", "input": {"prompt": "..."}}' \
  // -H "Authorization: Token $REPLICATE_API_TOKEN" \
  // -H 'Content-Type: application/json' \
  // https://api.replicate.com/v1/predictions

  // const replicateToken = "a0ef3c831ebeabcae041c46951850e27e5e37172";

  const response = await fetch('https://api.replicate.com/v1/predictions', {
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      "Authorization": `Token a0ef3c831ebeabcae041c46951850e27e5e37172`,
      "Access-Control-Request-Headers": "Content-Type, Authorization",
      // "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
    },
  });

  const result = await response.json();
  const url: string = result.data;

  return url.startsWith("https://replicate") ? url : "";
};
