import { CallResult, useCalls, useContractFunction } from "@usedapp/core";
import { BigNumber, Contract, ethers, utils } from "ethers";
import { ContestABI } from "./abi";

export type ContestProps = {
  address: string;
  sponsor: string;
  name: string;
  description: string;
  symbol: string;
  submissionFee: string;
  poolAmount: string;
  duration: string;
  numberSubmissions: string;
  leaderBoard: string[];
  rewardsDistributed: boolean;
};

export const useDistributeContestRewards = (address: string) => {
  const abi = new utils.Interface(ContestABI);

  const contest = new Contract(address, abi);

  const { state, send } = useContractFunction(contest, "endContest");
  return { state, send };
};

export const useClaimRewards = (address: string) => {
  const abi = new utils.Interface(ContestABI);

  const contest = new Contract(address, abi);

  const { state, send } = useContractFunction(contest, "withdrawRewards");
  return { state, send };
};

export const useClaimNFT = (address: string) => {
  const abi = new utils.Interface(ContestABI);

  const contest = new Contract(address, abi);

  const { state, send } = useContractFunction(contest, "claimNft");
  return { state, send };
};

export const useContestsInfo = (
  addresses: string[],
  chainId: number,
  url: string
) => {
  const calls = addresses.map((address) => {
    return buildContestInfoTransaction(address);
  });

  const flattenedCalls = calls.flat();
  const result = useCalls(flattenedCalls, { chainId: chainId });
  try {
    const array_chunks = (array: CallResult<Contract, string>[]) => {
      if (!array.length || !array[0]) {
        return [];
      }

      const rep = array.map((array) => array?.value);
      const chunks = [];

      let addressIndex = 0;
      for (
        let i = 0;
        i < array.length - 1;
        i += NUMBER_REQUESTS_TO_CONTEST_CONTRACT
      ) {
        const slice = rep.slice(i, i + NUMBER_REQUESTS_TO_CONTEST_CONTRACT);
        slice.push(addresses[addressIndex]);
        chunks.push(slice);
        addressIndex += 1;
      }
      return chunks;
    };

    const processedContests = array_chunks(result);

    let contestInfo: ContestProps[] = [];
    processedContests.forEach((contest) => {
      try {
        const info = buildContestInfo(contest);
        contestInfo.push(info);
      } catch (error) {}
    });
    return contestInfo;
  } catch {
    return [];
  }
};

export const NUMBER_REQUESTS_TO_CONTEST_CONTRACT = 10;

export const buildContestInfo = (tokenData: any): ContestProps => {
  const submissions: BigNumber = tokenData[4][0];
  const leaderBoard: BigNumber[] = tokenData[5][0];
  const date = new Date(tokenData[6][0] * 1000);
  const submissionFee: BigNumber = tokenData[7][0];
  const poolAmount: BigNumber = tokenData[8][0];

  const result: ContestProps = {
    sponsor: tokenData[0][0],
    name: tokenData[1][0],
    symbol: tokenData[2][0],
    description: tokenData[3][0],
    numberSubmissions: submissions.toString(),
    leaderBoard: leaderBoard.map((token) => token.toString()),
    duration: date.toLocaleDateString() + " " + date.toLocaleTimeString(),
    submissionFee: submissionFee.toString(),
    poolAmount: poolAmount.toString(),
    address: tokenData[10],
    rewardsDistributed: tokenData[9][0],
  };

  return result;
};

export const buildContestInfoTransaction = (address: string) => {
  const abi = new utils.Interface(ContestABI);
  const contestContract = new Contract(address, abi);
  return [
    // 0
    {
      contract: contestContract,
      method: "originalSponsor",
      args: [],
    },
    // 1
    {
      contract: contestContract,
      method: "name",
      args: [],
    },
    // 2
    {
      contract: contestContract,
      method: "symbol",
      args: [],
    },
    // 3
    {
      contract: contestContract,
      method: "description",
      args: [],
    },
    // 4
    {
      contract: contestContract,
      method: "_tokenIds",
      args: [],
    },
    // 5
    {
      contract: contestContract,
      method: "contestLeaderBoard",
      args: [],
    },
    // 6
    {
      contract: contestContract,
      method: "contestEnd",
      args: [],
    },
    // 7
    {
      contract: contestContract,
      method: "submissionFee",
      args: [],
    },
    // 8
    {
      contract: contestContract,
      method: "poolAmount",
      args: [],
    },
    // 9
    {
      contract: contestContract,
      method: "rewardsDistributed",
      args: [],
    },
  ];
};

// Function that returns the vote count of a PROMPT contest given an RPC url
// the address of the contest, and the token ID
export const getUserWithdrawableAmount = async (
  url: string,
  address: string,
  account: string
) => {
  const provider = ethers.getDefaultProvider(url);
  const abi = new utils.Interface(ContestABI);

  const contest = new Contract(address, abi, provider);

  const winnings: BigNumber = await contest.withdrawable(account);
  return winnings.toString();
};

// Function that returns the sponsor of the PROMPT contest given an RPC url
// and the address of the contest
export const getContestSponsor = async (url: string, address: string) => {
  const provider = ethers.getDefaultProvider(url);
  const abi = new utils.Interface(ContestABI);

  const contest = new Contract(address, abi, provider);

  return await contest.originalSponsor();
};

// Function that returns the name of the PROMPT contest given an RPC url
// and the address of the contest
export const getContestName = async (url: string, address: string) => {
  const provider = ethers.getDefaultProvider(url);
  const abi = new utils.Interface(ContestABI);

  const contest = new Contract(address, abi, provider);

  return await contest.name();
};

// Function that returns the symbol of the PROMPT contest given an RPC url
// and the address of the contest
export const getContestSymbol = async (url: string, address: string) => {
  const provider = ethers.getDefaultProvider(url);
  const abi = new utils.Interface(ContestABI);

  const contest = new Contract(address, abi, provider);

  return await contest.symbol();
};

// Function that returns the balance of the PROMPT contest given an RPC url
// and the address of the contest
export const getContestBalance = async (url: string, address: string) => {
  const provider = ethers.getDefaultProvider(url);
  const balance: BigNumber = await provider.getBalance(address);
  return balance.toString();
};

// Function that returns the description of the PROMPT contest given an RPC url
// and the address of the contest
export const getContestDescription = async (url: string, address: string) => {
  const provider = ethers.getDefaultProvider(url);
  const abi = new utils.Interface(ContestABI);

  const contest = new Contract(address, abi, provider);

  return await contest.description();
};

// Function that returns the number of submissions of the PROMPT contest given an RPC url
// and the address of the contest
export const getContestNumberOfSubmissions = async (
  url: string,
  address: string
) => {
  const provider = ethers.getDefaultProvider(url);
  const abi = new utils.Interface(ContestABI);

  const contest = new Contract(address, abi, provider);

  const numberSubmissions: BigNumber = await contest._tokenIds();
  return await numberSubmissions.toNumber();
};

// Function that returns the leading submission of a PROMPT contest given an RPC url
// and the address of the contest
export const getContestLeadingSubmission = async (
  url: string,
  address: string
) => {
  const provider = ethers.getDefaultProvider(url);
  const abi = new utils.Interface(ContestABI);

  const contest = new Contract(address, abi, provider);

  const leadingSubmission: BigNumber = await contest.leadingSubmissionID();
  return leadingSubmission.toString();
};

// Function that returns the vote count of a PROMPT contest given an RPC url
// the address of the contest, and the token ID
export const getContestSubmissionTally = async (
  url: string,
  address: string,
  tokenID: number
) => {
  const provider = ethers.getDefaultProvider(url);
  const abi = new utils.Interface(ContestABI);

  const contest = new Contract(address, abi, provider);

  const voteCount = await contest.submissions(tokenID);
  return voteCount;
};

// Function that returns the URI of a token of a PROMPT contest given an RPC url
// the address of the contest, and token ID
export const getContestSubmissionTokenURI = async (
  url: string,
  address: string,
  tokenID: number
) => {
  const provider = ethers.getDefaultProvider(url);
  const abi = new utils.Interface(ContestABI);

  const contest = new Contract(address, abi, provider);

  return await contest.tokenURI(tokenID);
};

// Function that returns the end date of a PROMPT contest given an RPC url
// and the address of the contest
export const getContestEnd = async (url: string, address: string) => {
  const provider = ethers.getDefaultProvider(url);
  const abi = new utils.Interface(ContestABI);

  const contest = new Contract(address, abi, provider);

  const contestEndUNIX: BigNumber = await contest.contestEnd();
  const date = new Date((await contestEndUNIX.toNumber()) * 1000);
  return date.toString();
};

// Function that returns the minimum submission of a PROMPT contest given an RPC url
// and the address of the contest
export const getContestSubmissionFee = async (url: string, address: string) => {
  const provider = ethers.getDefaultProvider(url);
  const abi = new utils.Interface(ContestABI);

  const contest = new Contract(address, abi, provider);

  const fee: BigNumber = await contest.submissionFee();
  return fee.toString();
};
