import { Box, Divider, Grid, SxProps, Typography } from "@mui/material";
import { ContestProps } from "../../api/contest";
import { useAppStore } from "../../store/appInfo";
import { formatBigNumber } from "../utils/helperFunctions/display";
import { sumContestSubmissions, sumPoolAmounts } from "../utils/helperFunctions/summaryStatistics";
import "@fontsource/oxanium/800.css";
import { PRIMARY_COLOR } from "../../utilities/colorway";

type props = {
  contests : ContestProps[]
};

const ContestsDetailsContainer = ({contests} : props) => {
  const chainID = useAppStore((state) => state.chainId);
  const totalPoolAmount = sumPoolAmounts(contests);
  const totalSubmissions = sumContestSubmissions(contests);
  return (
    <Box sx={FormControlStyles}>
      <Grid container rowSpacing={2}>
        <Grid item xs={12} justifyContent="center">
          <Box sx={CenteredBoxStyle}>
            <Typography variant="h3" sx={TypographyStyles}>
              Explore, Enter, Win...
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} justifyContent="center">
          <Divider sx={DividerStyles} />
        </Grid>
        <Grid item xs={4}>
          <Box sx={ColumnTextStyle}>
            <Typography variant="h5" sx={TypographyStyles}>Total Promptest Pools</Typography>
            <Typography variant="h6" sx={TypographyStyles}>{formatBigNumber(chainID, totalPoolAmount, true)}</Typography>
          </Box>
        </Grid>
        <Grid item xs={4}>
          <Box sx={ColumnTextStyle}>
            <Typography variant="h5" sx={TypographyStyles}>Promptests</Typography>
            <Typography variant="h6" sx={TypographyStyles}>{contests.length}</Typography>
          </Box>
        </Grid>
        <Grid item xs={4}>
          <Box sx={ColumnTextStyle}>
            <Typography variant="h5" sx={TypographyStyles}>Community Submissions</Typography>
            <Typography variant="h6" sx={TypographyStyles}>{totalSubmissions}</Typography>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

const FormControlStyles: SxProps = {
  padding: "5% 5% 2%",
  width: "70%",
};

const TypographyStyles: SxProps = {
  color: "white",
  fontFamily: "oxanium",
};

const CenteredBoxStyle: SxProps = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
};

const ColumnTextStyle: SxProps = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
};

const DividerStyles: SxProps = {
  background: PRIMARY_COLOR,
  borderBottomWidth: "5px",
};

export default ContestsDetailsContainer;
