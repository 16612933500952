import { Box, Divider, Grid, SxProps, Typography } from "@mui/material";
import "@fontsource/oxanium/800.css";

const CreateDetailsContainer = () => {
  return (
    <Box sx={FormControlStyles}>
      <Grid container rowSpacing={2}>
        <Grid item xs={12} justifyContent="center">
          <Box sx={CenteredBoxStyle}>
            <Typography variant="h3" sx={TypographyStyles}>
              Novel NFTs Fit To Your Needs
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} justifyContent="center">
          <Divider sx={DividerStyles} />
        </Grid>
        <Grid item xs={4}>
          <Box sx={ColumnTextStyle}>
            <Typography variant="h5" sx={TypographyStyles}>
              Spark Creativity
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={4}>
          <Box sx={ColumnTextStyle}>
            <Typography variant="h5" sx={TypographyStyles}>
            Crowdsource Art
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={4}>
          <Box sx={ColumnTextStyle}>
            <Typography variant="h5" sx={TypographyStyles}>
              Reward Participants
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

const FormControlStyles: SxProps = {
  padding: "5% 5% 2%",
  width: "70%",
};

const TypographyStyles: SxProps = {
  color: "white",
  fontFamily: "oxanium",
};

const CenteredBoxStyle: SxProps = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
};

const ColumnTextStyle: SxProps = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
};

const DividerStyles: SxProps = {
  background: "#2c0ff9",
  borderBottomWidth: "5px",
};

export default CreateDetailsContainer;
