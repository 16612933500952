import { Config, DAppProvider } from "@usedapp/core";
import React from "react";
import { LocalTestnet, CantoTestnet } from "./constants";

export const config: Config = {
  networks: [LocalTestnet, CantoTestnet],
  readOnlyUrls: {
    [LocalTestnet.chainId]: "http://localhost:8545",
    [CantoTestnet.chainId]: "https://eth.plexnode.wtf",
  },
};

interface IProviderProps {
  children: React.ReactNode;
}

const Provider = ({ children }: IProviderProps) => {
  return <DAppProvider config={config}>{children}</DAppProvider>;
};

export default Provider;
