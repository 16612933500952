import { useContractFunction } from "@usedapp/core";
import { BigNumber, Contract, ethers, utils } from "ethers";
import { contestFactoryAddresses } from "../config/constants";
import { ContestFactoryABI } from "./abi";

// Hook that lets users create a new contest. Requires a signature
export const useCreateNewContest = (chainId: number) => {
  const address = contestFactoryAddresses[chainId];
  const abi = new utils.Interface(ContestFactoryABI);

  const factory = new Contract(address, abi);

  const { state, send } = useContractFunction(factory, "createContest");
  return { state, send };
};

// Function that returns a PROMPT contest contract address given the url of the rpc,
// the chain id, and the contest number.
export const getContestAddress = async (
  url: string,
  chainId: number,
  contest: number
) => {
  const provider = ethers.getDefaultProvider(url);
  const address = contestFactoryAddresses[chainId];
  const abi = new utils.Interface(ContestFactoryABI);

  const factory = new Contract(address, abi, provider);

  return await factory.contests(contest);
};

// Function that returns the number of PROMPT contests for a given chain (rpc url and chain ID)
export const getNumberOfContests = async (url: any, chainId: number) => {
  const provider = ethers.getDefaultProvider(url);
  const address = contestFactoryAddresses[chainId];
  const abi = new utils.Interface(ContestFactoryABI);

  const factory = new Contract(address, abi, provider);

  const contestsBigNumber: BigNumber = await factory._contestIds();
  return contestsBigNumber.toNumber();
};

// Function that returns the addresses of each PROMPT contests for a given chain (rpc url and chain ID)
export const getAllContests = async (url: string, chainId: number) => {
  const provider = ethers.getDefaultProvider(url);
  const address = contestFactoryAddresses[chainId];
  const abi = new utils.Interface(ContestFactoryABI);
  
  const factory = new Contract(address, abi, provider);
  
  const contests = await factory.allContests();
  return contests;
};

// Function that returns the addresses of each PROMPT contests for a given chain (rpc url and chain ID)
export const getAllJudges = async (url: string, chainId: number) => {
  const provider = ethers.getDefaultProvider(url);
  const address = contestFactoryAddresses[chainId];
  const abi = new utils.Interface(ContestFactoryABI);
  
  const factory = new Contract(address, abi, provider);
  
  const contests = await factory.allJudges();
  return contests;
};
