import { Box, Button, Grid, SxProps } from "@mui/material";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ContestProps } from "../../api/contest";
import { useAppStore } from "../../store/appInfo";
import {
  BACKGROUND_COLOR,
  PRIMARY_COLOR,
  PRIMARY_TEXT_COLOR,
  TRANSPARENT,
} from "../../utilities/colorway";
import ContestDetailsContainer from "../containers/ContestDetailsContainer";
import SubmissionsContainer from "../containers/SubmissionsContainer";
import ResponsiveAppBar from "../header/ResponsiveAppBar";
import NewContestSubmissionForm from "../inputs/NewContestSubmissionForm";

const ContestPage = () => {
  const { address } = useParams();
  const contests = useAppStore((state) => state.contests);
  const [galleryTab, setGalleryTab] = useState(true);

  let contest: ContestProps = contests[0];
  contests.forEach((value) => {
    if (value.address === address) {
      contest = value;
      return;
    }
  });

  return contest != null ? (
    <>
      <ResponsiveAppBar title={contest.symbol} />
      <Box sx={landingContainerStyles}>
        <ContestDetailsContainer contest={contest} />
        <Grid
          container
          width="75%"
          display="flex"
          justifyContent="space-between"
          mb={1}
          border={"2px solid " + PRIMARY_COLOR}
        >
          <Grid item xs={6}>
            <Button
              sx={!galleryTab ? OtherTabStyles : SelectedTabStyles}
              onClick={() => {
                if (!galleryTab) {
                  setGalleryTab(true);
                }
              }}
            >
              Gallery
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              sx={galleryTab ? OtherTabStyles : SelectedTabStyles}
              onClick={() => {
                if (galleryTab) {
                  setGalleryTab(false);
                }
              }}
            >
              Make a submission
            </Button>
          </Grid>
        </Grid>
        {!galleryTab ? (
          <NewContestSubmissionForm contest={contest} />
        ) : (
          <SubmissionsContainer contest={contest} />
        )}
      </Box>
    </>
  ) : (
    <div></div>
  );
};

const landingContainerStyles: SxProps = {
  width: "100%",
  display: "flex",
  minHeight: "80rem",
  flexDirection: "column",
  alignItems: "center",
  background: BACKGROUND_COLOR,
};

const OtherTabStyles: SxProps = {
  display: "flex",
  width: "100%",
  background: TRANSPARENT,
  color: PRIMARY_TEXT_COLOR,
  borderRadius: 0,
  fontSize: "1.2rem",
};

const SelectedTabStyles: SxProps = {
  display: "flex",
  width: "100%",
  background: PRIMARY_COLOR,
  color: PRIMARY_TEXT_COLOR,
  borderRadius: 0,
  fontSize: "1.2rem",
};

export default ContestPage;
